<script>
  export let logo, companyName, phoneIcon;
</script>

{@html "<!--Header-->"}

<header class="absolute w-screen z-50 opacity-90">
  <div class="content">
    <div class="header__container text-white">
      <div class="logo">
        <a target="_blank" href="https://www.akamai.com/">
          <img src={logo} alt="{companyName} Logo" />
        </a>
      </div>
      <div class="button__container flex flex-col whitespace-nowrap">
        <div class="top flex-row justify-end tel-desktop">
          <a href="tel:18774252624" rel="noindex">
            <span>+1-8774252624</span></a
          >
          <div class="cmp-header__header__icons absolute mobile-icon top-1">
            <a
              href="tel:18774252624"
              data-geo-phone=""
              class="icon icon--phone"
              rel="noindex"
            />
          </div>
        </div>
        <div class="bottom flex-row">
          <a href="https://www.akamai.com/products" target="_blank">
            <button
              href="https://www.akamai.com/products"
              target="_blank"
              class="button-1 text-center">Try Akamai</button
            >
          </a>
          <a
            href="https://www.akamai.com/why-akamai/stop-cyberthreats"
            target="_blank"
          >
            <button class="button-2 text-center">Under Attack?</button></a
          >
        </div>
        <div />
      </div>
    </div>
  </div>
</header>

<style lang="scss">
  $button-blue: #3e8ed4;
  $button-red: #ce3539;

  header {
    background-color: #000;
    height: 104px;
  }

  .header__container {
    .mobile-icon {
      display: none;
    }
    @media (min-width: 0px) and (max-width: 544px) {
      .tel-desktop span {
        visibility: hidden !important;
      }
      .mobile-icon {
        display: block !important;
      }
    }

    max-width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    .logo {
      height: 81px;
      width: 141px;
      min-width: 120px;
      margin-left: 218px;

      @media (min-width: 1024px) and (max-width: 1280px) {
        margin-left: 114px;
      }
      @media (min-width: 767px) and (max-width: 1023px) {
        margin-left: 60px;
      }
      @media (min-width: 680px) and (max-width: 767px) {
        margin-left: 40px;
      }
      @media (min-width: 0px) and (max-width: 679px) {
        margin-left: 5px;
      }
    }
    .button__container {
      margin-top: 17px;
      margin-bottom: 19px;
      margin-right: 238px;
      @media (min-width: 1024px) and (max-width: 1280px) {
        margin-right: 114px;
      }
      @media (min-width: 767px) and (max-width: 1023px) {
        margin-right: 60px;
      }
      @media (min-width: 680px) and (max-width: 767px) {
        margin-right: 40px;
      }
      @media (min-width: 0px) and (max-width: 679px) {
        margin-right: 5px;
      }

      .top {
        display: flex;
        flex-direction: row;
        font-size: 10px;
        padding-bottom: 13px;
      }

      button {
        font-size: 14px;
        @media (max-width: 544px) {
          font-size: 12px;
        }
        @media (max-width: 320px) {
          font-size: 9px;
        }
      }
      .button-1 {
        transition: background-color 0.3s;
        margin-right: 15px;
        border: 1px solid $button-blue;
        width: 93px;
        height: 42px;
        &:hover {
          background-color: rgba(1, 122, 198, 0.2);
        }
        @media (max-width: 544px) {
          width: auto;
          height: auto;
          margin-right: 6px;
          padding: 10px 14px;
        }
        @media (max-width: 320px) {
          padding: 6px 8px;
        }
      }
      .button-2 {
        transition: background-color 0.3s;
        border: 1px solid $button-red;
        width: 113px;
        height: 42px;
        &:hover {
          background-color: rgba(208, 52, 58, 0.2);
        }
        @media (max-width: 544px) {
          width: auto;
          height: auto;
          padding: 10px 14px;
        }
        @media (max-width: 320px) {
          padding: 6px 8px;
        }
      }
    }
  }

  @import "./stylesheets/akamai-components.css";
</style>
