<script>
  export let infoGridImage;
</script>

{@html "<!--Info Grid-->"}

<section id="info-grid">
  <div class="content max-w-full">
    <div class="top md:flex-row flex-col flex">
      <div class="h2__container relative text-center sm:text-left">
        <h2>
          Protect Subscribers, Differentiate Services, and Get on a Path to Generate Revenue
        </h2>
      </div>
      <div class="content__block top">
        <p class="text-left">
          ISPs and MNOs everywhere are always looking for opportunities to
          improve their services. Speed and reliability aren’t sufficient
          anymore. Awareness of exposure on the internet creates an opportunity
          for providers to extend internet access offers with security services.<br
          /><br /> Akamai is hosting personalized briefings that explore security
          service strategies and the role and importance of threat intelligence.
        </p>
      </div>
    </div>
    <div class="bottom flex-col md:flex-row flex mt-5 sm:m-t0">
      <img
        class="object-cover info__grid--img"
        src={infoGridImage}
        alt=" a man with white hair and glasses wearing overalls collaborating with a black haired woman in overalls on tablets"
      />
      <div class="content__block bottom text-left">
        <h2 class="text-center sm:text-left">Security Service Strategies</h2>
        <p>
          This discussion starts with why security services should be an
          integral part of ISP and MNO portfolios. It covers market trends that
          are creating demand, service requirements, and associated business
          models. It closes with Go-To-Market approaches, and how new services
          support established industry metrics (KPIs) to increase revenue, and
          improve customer satisfaction and retention.
        </p>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  $info-grid__border-color: #3e8ed4;
  section {
    padding-bottom: 102px;
    background-color: white;
    display: flex;
    .info__grid--img  {
      @media(max-width:680px) {
        margin-bottom: 15px;
      }
    }
    .content {
      --content__padding--sides: 238px;
      --content__padding--sides-mobile: 40px;

      padding: 0 var(--content__padding--sides);
      margin: 0 auto;
      @media (min-width: 1024px) and (max-width: 1280px) {
        padding: 0 calc(var(--content__padding--sides) * 0.6);
      }
      @media (min-width: 545px) and (max-width: 1023px) {
        padding: 0 calc(var(--content__padding--sides) * 0.3);
      }
      @media (min-width: 0px) and (max-width: 544px) {
        padding: 0 var(--content__padding--sides-mobile);
      }

      img {
        @media (min-width: 544px) {
          max-width: 392px;
          margin: 0 auto;
        }
      }
    }

    @media (max-width: 768px) {
      padding-bottom: 60px;
    }
    @media (max-width: 544px) {
      img {
        max-width: 100%;
      }
    }
  }

  .content__block.top {
    margin-top: 60px;
    @media (min-width: 0px) and (max-width: 679px) {
      margin-left: 0px;
      margin-top: 0px;
      max-width: none;
    }
    @media (min-width: 768px) {
      margin-left: 57px;

      max-width: 376px;
    }
    @media (max-width: 768px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    @media (max-width: 544px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .content__block.bottom {
    h2 {
      padding-top: 24px;
      padding-bottom: 22px;
    }
    position: relative;
    top: 113px;
    border-top: 1px solid $info-grid__border-color;
    max-width: 474px;
    margin-left: 98px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 50px;
      top: 0px;
    }
    @media (min-width: 0px) and (max-width: 767px) {
      margin-left: 0px;
      max-width: none;
      top: 0px;
      max-width: 100%;
      border-top: none;

    }
  }
  .h2__container {
    background-color: white;
    top: -173px;
    max-width: 531px;
    padding: 39px 57px 18px 38px;
    @media (min-width: 768px) and (max-width: 1023px) {
      margin-left: 20px;
    }
    @media (min-width: 0px) and (max-width: 767px) {
      top: 0px;
      max-width: 100%;
      padding: 20px 0px;
    }
  }
</style>
