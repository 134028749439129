<script>
  import { onMount } from "svelte";
  onMount(() => {
    //Formspree fetch
    var form = document.getElementById("formspree");
    async function handleSubmit(event) {
      event.preventDefault();
      var data = new FormData(event.target);
      fetch(event.target.action, {
        method: form.method,
        body: data,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          console.log(response);
          if (response.ok) {
            setTimeout(() => {
              document.getElementById("form").innerHTML =
                "<div class='w-full flex justify-center items-center flex-col h-44 '><h2 class='form-submitted' style='font-family: Roboto light'>Thank you for submitting the form!</h2><h3 class='mt-3' style='font-family: Roboto regular; font-size: 16px;'>We will be in touch with you shortly.</h3></div>";
            }, 150);
            console.log("Powered by React Redux PRO");
            event.preventDefault();
          } else {
            response.json().then((data) => {
              if (Object.hasOwn(data, "errors")) {
                console.log("Error");
              } else {
                console.log("Problem submitting form");
              }
            });
          }
        })
        .catch((error) => {
          console.log("Caught error");
        });
    }
    form.addEventListener("submit", handleSubmit);
  });
</script>

{@html "<!--Form-->"}

<section id="form">
  <div class="content flex flex-col">
    <div class="form__header text-center">
      <h2>Knowledge is Power</h2>
      <h3>
        Complete the form below to schedule a briefing on security service strategies.
      </h3>
    </div>
    <form
      id="formspree"
      action="https://formspree.io/f/xqknwagd"
      method="POST"
      class="relative col-span-12 md:col-span-8 md:col-start-3"
    >
      <div class="grid">
        <div class="grid-cols-1 md:grid-cols-2 grid form__columns">
          <label for="first-name">
            First Name: <span class="asterisk__color">*</span>
            <input
              tabindex="0"
              class="block mt-4 mb-5"
              type="first-name"
              name="First Name"
              autocomplete="given-name"
              required
            />
          </label>
          <label for="last-name">
            Last Name: <span class="asterisk__color">*</span>
            <input
              class="block mt-4 mb-5"
              type="last-name"
              name="Last Name"
              autocomplete="family-name"
              required
            />
          </label>
        </div>
        <div class="grid-cols-1 md:grid-cols-2 grid form__columns">
          <label for="email">
            Email: <span class="asterisk__color">*</span>
            <input
              class="block mt-4 mb-5"
              type="email"
              name="Email Address"
              autocomplete="email"
              required
            />
          </label>
          <label for="Company">
            Company: <span class="asterisk__color">*</span>
            <input
              class="block mt-4 mb-5"
              type="company"
              name="Company"
              required
            />
          </label>
        </div>
        <div class="grid-cols-1 md:grid-cols-2 grid form__columns">
        <label for="title">
          Title: <span class="asterisk__color">*</span>
          <input
            name="Title"
            class="block mt-4 mb-5"
            required
          />
        </label>
        <label for="country">
          Country: <span class="asterisk__color">*</span>
          <select
            name="Country"
            id="country"
            class="block pl-1 pr-5 mt-4 mb-5 w-full h-10"
            required
          >
            <option disabled selected value style="display:none" />
            <option value="Afganistan">Afghanistan</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bonaire">Bonaire</option>
            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Ter"
              >British Indian Ocean Ter</option
            >
            <option value="Brunei">Brunei</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Canary Islands">Canary Islands</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic"
              >Central African Republic</option
            >
            <option value="Chad">Chad</option>
            <option value="Channel Islands">Channel Islands</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cocos Island">Cocos Island</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote DIvoire">Cote DIvoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Curaco">Curacao</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="East Timor">East Timor</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands">Falkland Islands</option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French Southern Ter">French Southern Ter</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Great Britain">Great Britain</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guinea">Guinea</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="Indonesia">Indonesia</option>
            <option value="India">India</option>
            <option value="Iran">Iran</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Isle of Man">Isle of Man</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea North">Korea North</option>
            <option value="Korea Sout">Korea South</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Laos">Laos</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libya">Libya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macau">Macau</option>
            <option value="Macedonia">Macedonia</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Malawi">Malawi</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Midway Islands">Midway Islands</option>
            <option value="Moldova">Moldova</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Nambia">Nambia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherland Antilles">Netherland Antilles</option>
            <option value="Netherlands">Netherlands (Holland, Europe)</option>
            <option value="Nevis">Nevis</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau Island">Palau Island</option>
            <option value="Palestine">Palestine</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Phillipines">Philippines</option>
            <option value="Pitcairn Island">Pitcairn Island</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Republic of Montenegro"
              >Republic of Montenegro</option
            >
            <option value="Republic of Serbia">Republic of Serbia</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russia">Russia</option>
            <option value="Rwanda">Rwanda</option>
            <option value="St Barthelemy">St Barthelemy</option>
            <option value="St Eustatius">St Eustatius</option>
            <option value="St Helena">St Helena</option>
            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
            <option value="St Lucia">St Lucia</option>
            <option value="St Maarten">St Maarten</option>
            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
            <option value="St Vincent & Grenadines"
              >St Vincent & Grenadines</option
            >
            <option value="Saipan">Saipan</option>
            <option value="Samoa">Samoa</option>
            <option value="Samoa American">Samoa American</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syria">Syria</option>
            <option value="Tahiti">Tahiti</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Thailand">Thailand</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Erimates">United Arab Emirates</option>
            <option value="United States of America"
              >United States of America</option
            >
            <option value="Uraguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Vatican City State">Vatican City State</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
            <option value="Wake Island">Wake Island</option>
            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
            <option value="Yemen">Yemen</option>
            <option value="Zaire">Zaire</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
          </select>
        </label>
        </div>
        <div class="grid-cols-1 md:grid-cols-2 grid form__columns">
          <label for="phone-number">
            Phone Number: <span class="asterisk__color">*</span>
            <input
              class="block mt-4 mb-5"
              type="tel"
              name="Phone Number"
              autocomplete="tel"
              required
            />
          </label>
          <label for="topic">
            Choose Your Topic: <span class="asterisk__color">*</span>
            <select
              name="Topic"
              id="topic"
              class="block pl-1 pr-5 mt-4 mb-5 w-full h-10"
              required
            >
              <option disabled selected value style="display:none" />
              <option value="Protecting your customers"
                >Protecting your customers</option
              >
              <option value="Zero Trust">Zero Trust</option>
              <option value="Threat Intelligence">Threat Intelligence</option>
              <option value="Web Security">Web Security</option>
              <option value="Enterprise Security">Enterprise Security</option>
            </select>
          </label>
      </div>
      <div class="grid grid-cols-1">
        <label for="comments">
          Comments or Questions:
          <textarea
            class="block mt-4 mb-5"
            type="comments"
            name="Comments or Questions"
          />
        </label>
      </div>

      </div>
      <div class="flex flex-row">
        <label
          ><input
            class="checkbox cursor-pointer"
            type="checkbox"
            name="Marketing Opt in"
            required
          /></label
        >
      </div>
      <p
        class="checkbox__text relative cursor-pointer"
        on:click={() => {
          document.querySelector(".checkbox").click();
        }}
      >
        I’d like to receive more information from Akamai. By submitting this
        form, I am providing consent to receive marketing communications and I
        understand and agree to the usage of my corporate contact information in
        accordance with Akamai’s privacy statement.
      </p>
      <div class="w-full text-center button-container">
        <button class="submit-form" type="submit"> Submit</button>
      </div>
    </form>
  </div>
</section>

<style lang="scss">
  $bg-form: #f4f5f4;
  $border-color: #3e8ed4;
  $asterisk-color: #ce3539;
  $checkbox-accent-color: #ff9933;
  $button-color: #017ac6;
  :global #form {
    .form-submitted {
      font-size: 2rem;
      @media (max-width: 1024px) {
        font-size: 2rem !important;
      }

      @media (max-width: 544px) {
        font-size: 1.5rem !important;
      }
    }
  }
  .title-field::-webkit-calendar-picker-indicator {
    @media (min-width: 544px) {
      display: none !important;
    }
  }
  .title-field::-webkit-list-button {
    display: none !important;
  }

  .title-field {
    @media (min-width: 544px) {
      padding: 10px;
      padding-right: 30px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url("./images/caret-line.svg");
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 0.65em auto, 100%;
    }
  }
  form {
    input[type="checkbox"] {
      accent-color: $checkbox-accent-color;
      width: 19px;
      height: 19px;
      position: absolute;
      margin: 0 21px;
      border-radius: 0px;
      transition: all 2s;
      @media (max-width: 544px) {
        margin-left: 0px;
        margin-right: 0px;
      }

      &:checked {
        transition: all 2s;
      }
    }
    select {
      padding: 10px;
      padding-right: 30px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url("./images/caret-line.svg");
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 0.65em auto, 100%;
    }

    /* For IE (thanks to @SaiManoj) */
    select::-ms-expand {
      display: none;
    }
    select {
      background-color: white;
    }
    .form__columns {
      --grid-cols-gap: 102px;
      @media (min-width: 1280px) {
        column-gap: var(--grid-cols-gap);
      }
      @media (min-width: 1024px) and (max-width: 1280px) {
        column-gap: calc(var(--grid-cols-gap) * 0.8);
      }
      @media (min-width: 680px) and (max-width: 1024px) {
        column-gap: calc(var(--grid-cols-gap) * 0.5);
      }
      @media (min-width: 0px) and (max-width: 679px) {
        column-gap: 0px;
      }
    }
    input {
      padding: 8px;
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box; /* Opera/IE 8+ */
      width: 100%;
    }
    .checkbox__text {
      opacity: 0.5 !important;
      position: relative;
      left: 60px;
      max-width: 924px;
      @media (max-width: 544px) {
        max-width: 80%;
      }
    }

    textarea {
      @media (min-width: 1280px) {
        width: 968px;
      }
      @media (min-width: 1024px) and (max-width: 1280px) {
        width: 100%;
      }
      @media (min-width: 680px) and (max-width: 1024px) {
        width: 100%;
      }
      @media (min-width: 0px) and (max-width: 680px) {
        width: 100%;
      }
      height: 96px;
      padding: 8px;
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box; /* Opera/IE 8+ */
    }
    @media (min-width: 0px) and (max-width: 680px) {
      margin: 0 auto;
      width: 80%;
    }
  }

  .asterisk__color {
    color: $asterisk-color;
  }
  section {
    background-color: $bg-form;
  }
  .content {
    --content__padding--sides: 238px;
    --content__padding--sides-mobile: 40px;

    display: flex;
    margin: 0 auto;
    padding-top: 48px;
    padding-bottom: 101px;
    padding-left: var(--content__padding--sides);
    padding-right: var(--content__padding--sides);

    @media (min-width: 1024px) and (max-width: 1280px) {
      padding-left: calc(var(--content__padding--sides) * 0.8);
      padding-right: calc(var(--content__padding--sides) * 0.8);
    }
    @media (min-width: 680px) and (max-width: 1023px) {
      padding-left: calc(var(--content__padding--sides) * 0.5);
      padding-right: calc(var(--content__padding--sides) * 0.5);
    }
    @media (min-width: 0px) and (max-width: 679px) {
      padding-left: var(--content__padding--sides-mobile);
      padding-right: var(--content__padding--sides-mobile);
    }
    max-width: 1440px;
    .form__header {
      h2 {
        margin-bottom: 24px;
      }
      h3 {
        margin-bottom: 50px;
      }
    }

    .button-container {
      margin-top: 54px;

      .submit-form {
        width: 152px;
        color: white;
        height: 51px;
        background: #017ac6;
        &:hover {
          transition: background-color 0.3s ease-in-out;
          background-color: #009cde;
        }
        @media (min-width: 545px) and (max-width: 677px) {
          width: auto;
          padding: 10px 38px;
        }
        @media (min-width: 0px) and (max-width: 544px) {
          width: 80%;
          padding: 10px 38px;
        }
      }
    }
  }
</style>
