<script>
  import { gsap } from "gsap";
  import { ScrollTrigger } from "gsap/ScrollTrigger";
  import { ScrollToPlugin } from "gsap/all";
  import { onMount } from "svelte";
  import { DrawSVGPlugin } from "../scripts/DrawSVGPlugin";
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(DrawSVGPlugin);

  onMount(async () => {
      // Info Grid
      gsap.fromTo(
        ".info__grid--img",
        { y: 0 },
        {
          ease: "Power3.out",
          y: 25,
          scrollTrigger: {
            trigger: "#info-grid",
            markers: false,
            start: "top top",
            end: "bottom top",
            scrub: 1.5,
          },
        }
      );
      //Info Row
      // gsap.to(".bg-image", {
      //   backgroundPositionX: "-50px",
      //   ease: "Power3.out",
      //   scrollTrigger: {
      //     trigger: "#info-row",
      //     markers: false,
      //     start: "top bottom",
      //     end: "bottom top",
      //     scrub: 0.5,
      //   },
      // });

      //All sections
      gsap.utils.toArray("section").forEach((section) => {
        if (section.id !== "foundations") {
          gsap.from(section.querySelectorAll("h2, h3, p, img"), {
            scrollTrigger: section,
            autoAlpha: 0,
            y: 25,
            duration: 1.25,
            stagger: 0.25,
          });
        } else if (section.id == "foundations") {
          gsap.from(section.querySelectorAll("h2, p, h4"), {
            scrollTrigger: section,
            autoAlpha: 0,
            y: 25,
            duration: 1.25,
            stagger: 0.25,
          });
        }

        //Mobile Foundations
        ScrollTrigger.matchMedia({
          "(max-width: 544px)": function () {
            gsap.fromTo(
              section.querySelectorAll(
                ".foundations-icon path, line, circle, rect"
              ),
              {
                drawSVG: "0% 0%",
              },
              {
                scrollTrigger: {
                  trigger: "#foundations",
                  start: "top bottom",
                  end: "bottom top",
                },
                duration: 3.45,
                delay: 0,
                ease: "power3",
                drawSVG: "100% 0%",
              }
            );
            gsap.from(section.querySelectorAll(".foundations__h3"), {
              scrollTrigger: {
                trigger: "#foundations",
                markers: false,
                start: "top bottom",
                end: "bottom top",
              },
              autoAlpha: 0,
              y: 25,
              immediateRender: false,
              duration: 1.25,
              stagger: 0.25,
            });
            gsap.fromTo(
              section.querySelectorAll(".foundations-icon"),
              { y: 15 },
              {
                scrollTrigger: {
                  trigger: "#foundations",
                  markers: false,
                  start: "top bottom",
                  end: "bottom top",
                },
                duration: 1.55,
                ease: "power2",
                y: -15,
              }
            );
          },
          "(min-width: 545px)": function () {
            gsap.fromTo(
              section.querySelectorAll(
                ".foundations-icon path, line, circle, rect"
              ),
              {
                drawSVG: "0% 0%",
              },
              {
                scrollTrigger: {
                  start: "center center",

                  trigger: ".foundations-icon-trigger",
                },
                duration: 3.45,
                delay: 0,
                ease: "power3",
                drawSVG: "100% 0%",
              }
            );
            gsap.from(section.querySelectorAll(".foundations__h3"), {
              scrollTrigger: {
                trigger: ".foundations-icon-trigger",
                markers: false,
                start: "center center",
                end: "bottom top",
              },
              autoAlpha: 0,
              y: 25,
              immediateRender: false,
              duration: 1.25,
              stagger: 0.25,
            });
            gsap.fromTo(
              section.querySelectorAll(".foundations-icon"),
              { y: 15 },
              {
                scrollTrigger: {
                  trigger: ".foundations-icon-trigger",
                  markers: false,
                  start: "center center",
                  end: "bottom top",
                },
                duration: 1.55,
                ease: "power2",
                y: -15,
              }
            );
          },
        });

        if (section.id !== "form") {
          gsap.from(section.querySelectorAll("button"), {
            scrollTrigger: section,
            autoAlpha: 0,
            y: 15,
            duration: 1,
            delay: 0.75,
            stagger: 0.25,
          });
        }

        //Mobile
        ScrollTrigger.matchMedia({
          "(max-width: 544px)": function () {
            gsap.fromTo(
              ".h2__container",
              { y: 10 },
              {
                y: 0,
                ease: "Power3.out",
                scale: 1.01,
                scrollTrigger: {
                  trigger: "#info-grid",
                  markers: false,
                  start: "top top",
                  end: "bottom top",
                  scrub: 1.5,
                },
              }
            );

            gsap.utils.toArray("h2").forEach((h2) => {
              if (!h2.classList.contains("cmp-title__text")) {
                gsap.fromTo(
                  h2,
                  { y: 12 },
                  {
                    ease: "slow.out",
                    y: 0,
                    scrollTrigger: {
                      trigger: h2,
                      markers: false,
                      start: "top bottom",
                      end: "bottom top",
                      scrub: 0.5,
                    },
                    duration: 5000,
                  }
                );
              }
            }, 150);
          },
          "(min-width: 545px)": function () {
            // Hero
            gsap.fromTo(
              ".h2__container",
              { y: 50 },
              {
                y: 0,
                ease: "Power3.out",
                scale: 1.01,
                scrollTrigger: {
                  trigger: "#info-grid",
                  markers: false,
                  start: "top top",
                  end: "bottom top",
                  scrub: 1.5,
                },
              }
            );
            gsap.utils.toArray("h2").forEach((h2) => {
              if (!h2.classList.contains("cmp-title__text")) {
                gsap.fromTo(
                  h2,
                  { y: 8 },
                  {
                    ease: "slow.out",
                    y: -2,
                    scrollTrigger: {
                      trigger: h2,
                      markers: false,
                      start: "top bottom",
                      end: "bottom top",
                      scrub: 0.5,
                    },
                    duration: 5000,
                  }
                );
              }
            }, 150);
          },
        });

      });
  });
</script>

{@html "<!--Hero-->"}

<section id="hero" class="overflow-hidden">
  <div class="content">
    <div
      class="hero__container text-white z-10 absolute top-0 left-0 text-left"
    >
      <h1 class="whitespace-nowrap">Safe. Everywhere.</h1>
      <p>
        Take advantage of edge networking and data science to protect your
        businesses and subscribers from today’s threats.
      </p>
      <div class="text-center mt-5 sm:mt-0 sm:text-left">
        <button
          on:click={() => {
            gsap.to(window, {
              duration: 2,
              ease: "power3",
              scrollTo: { y: "#form", offsetY: 0 },
            });
          }}>Register for your security briefing</button
        >
      </div>
    </div>
  </div>
  <div class="hero__img" />
</section>

<style lang="scss">
  section {
    height: 655px;

    .content {
      position: relative;
      max-width: 1440px;
      display: flex;
      margin: 0 auto;
    }
  }
  .hero__img {
    width: 100%;
    height: 100%;
    background-image: url("./images/hero-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    @media (min-width: 0px) and (max-width: 677px) {
      background-position: 75% 0%;
    }
  }
  .hero__container {
    --content__padding--sides-left: 238px;
    --content__padding--sides-right: 630px;
    --content__padding--sides-mobile: 40px;

    padding-top: 187px;
    padding-bottom: 376px;
    padding-left: var(--content__padding--sides-left);
    padding-right: var(--content__padding--sides-right);

    @media (min-width: 1024px) and (max-width: 1280px) {
      padding-left: calc(var(--content__padding--sides-left) * 0.8);
      padding-right: calc(var(--content__padding--sides-right) * 0.8);
    }
    @media (min-width: 545px) and (max-width: 1023px) {
      padding-left: calc(var(--content__padding--sides-left) * 0.5);
      padding-right: calc(var(--content__padding--sides-right) * 0.5);
    }
    @media (min-width: 370px) and (max-width: 544px) {
      max-width: 100%;
      padding-left: var(--content__padding--sides-mobile);
      padding-right: var(--content__padding--sides-mobile);
    }
    @media (min-width: 0px) and (max-width: 369px) {
      padding-left: calc(var(--content__padding--sides-mobile) * 0.5);
      padding-right: calc(var(--content__padding--sides-mobile) * 0.5);
    }

    h1 {
      // font-size: 70px;
      padding-bottom: 16px;
    }
    p {
      padding-bottom: 40px;
    }
    button {
      background: #017ac6;
      border: 1px solid #017ac6;
      width: 297px;
      height: 51px;
      &:hover {
        transition: background-color 0.3s ease-in-out;
        background-color: #009cde;
      }
      @media (min-width: 0px) and (max-width: 677px) {
        width: auto;
        padding: 10px 20px;
      }
    }
  }
</style>
