<script>
  export let logo;
</script>

{@html "<!--Footer-->"}

<div class="footer aem-GridColumn aem-GridColumn--default--12">
  <div class="cmp-footer" id="footer-04392e34e7">
    <div class="cmp-footer__section1">
      <div class="cmp-footer__s1left">
        <div class="cmp-footer__image">
          <div class="cmp-image__wrapper ">
            <div
              data-cmp-lazy=""
              data-title="Akamai Logo"
              class="cmp-image"
              itemscope=""
              itemtype="http://schema.org/ImageObject"
            >
              <a
                class="cmp-image__link"
                href="https://www.akamai.com/"
                data-cmp-hook-image="link"
              >
                <img
                  src={logo}
                  class="cmp-image__image"
                  itemprop="contentUrl"
                  data-cmp-hook-image="image"
                  alt="Akamai Logo"
                  title="Akamai Logo"
                />
              </a>

              <meta itemprop="caption" content="Akamai Logo" />
            </div>
          </div>
        </div>
        <div class="cmp-footer__socialLink">
          <div class="cmp-sociallinks relative">
            <a
              class="cmp-sociallink"
              href="https://twitter.com/Akamai"
              target="_blank"
            >
              <span class="akam-visually-hidden">Twitter</span>
              <i class="icon-twitter" />
            </a>

            <a
              class="cmp-sociallink"
              href="https://www.facebook.com/AkamaiTechnologies/"
              target="_blank"
            >
              <span class="akam-visually-hidden">Facebook</span>
              <i class="icon-facebook22" />
            </a>

            <a
              class="cmp-sociallink"
              href="https://www.youtube.com/user/akamaitechnologies"
              target="_blank"
            >
              <span class="akam-visually-hidden">YouTube</span>
              <i class="icon-youtube-play" />
            </a>

            <a
              class="cmp-sociallink"
              href="https://www.linkedin.com/company/akamai-technologies"
              target="_blank"
            >
              <span class="akam-visually-hidden">LinkedIn</span>
              <i class="icon-linkedin2" />
            </a>
          </div>
        </div>
      </div>
      <div class="cmp-footer__s1right">
        <div class="cmp-footer__s2-col1">
          <div class="cmp-footer__title1">
            <div class="title title__footer title__white">
              <div class="cmp-title">
                <h2 class="cmp-title__text">Solutions</h2>
              </div>
            </div>
          </div>
          <div class="cmp-footer__list1">
            <div class="list cmp-list__gray-link">
              <ul class="cmp-list" id="list-713792faa9">
                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/solutions/security"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Security </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/solutions/content-delivery-network"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Content Delivery (CDN)
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/solutions/edge"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Edge Compute </span>
                    </a>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="cmp-footer__s2-col2">
          <div class="cmp-footer__title2">
            <div class="title title__footer title__white">
              <div class="cmp-title">
                <h2 class="cmp-title__text">
                  <a
                    class="cmp-title__link"
                    href="https://www.akamai.com/company/about">Company</a
                  >
                </h2>
              </div>
            </div>
          </div>
          <div class="cmp-footer__list2">
            <div class="list cmp-list__gray-link">
              <ul class="cmp-list" id="list-8fcfbdbeee">
                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/company/company-history"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> History </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/company/facts-figures"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Facts and Figures
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/company/leadership"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Leadership </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/company/leadership/board-of-directors"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Board of Directors
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.ir.akamai.com/"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Investor Relations
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/company/corporate-responsibility"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Environmental, Social, and Governance
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/company/ethics-and-compliance"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Ethics </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/company/locations"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Locations </span>
                    </a>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="cmp-footer__s2-col3">
          <div class="cmp-footer__title3">
            <div class="title title__footer title__white">
              <div class="cmp-title">
                <h2 class="cmp-title__text">
                  <a
                    class="cmp-title__link"
                    href="https://www.akamai.com/newsroom">Newsroom</a
                  >
                </h2>
              </div>
            </div>
          </div>
          <div class="cmp-footer__list3">
            <div class="list cmp-list__gray-link">
              <ul class="cmp-list" id="list-724d4600da">
                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/newsroom/press-release"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Press Releases </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/newsroom/in-the-news"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> In the News </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/company/our-awards"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Awards </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/newsroom/media-resources"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Media Resources
                      </span>
                    </a>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="cmp-footer__s2-col4">
          <div class="cmp-footer__title4">
            <div class="title title__footer title__white">
              <div class="cmp-title">
                <h2 class="cmp-title__text">
                  <a
                    class="cmp-title__link"
                    href="https://www.akamai.com/careers">Careers</a
                  >
                </h2>
              </div>
            </div>
          </div>
          <div class="cmp-footer__list4">
            <div class="list cmp-list__gray-link">
              <ul class="cmp-list" id="list-1d7f0f6b9d">
                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/careers/working-at-akamai"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Working at Akamai
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/careers/students-and-recent-graduates"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Students and Recent Grads
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/careers/workplace-diversity"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Workplace Diversity
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://akamaicareers.inflightcloud.com/search"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Search Jobs </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/blog/culture"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Careers Blog </span>
                    </a>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="cmp-footer__s2-col6">
          <div class="cmp-footer__title6">
            <div class="title title__footer title__white">
              <div class="cmp-title">
                <h2 class="cmp-title__text">
                  <a
                    class="cmp-title__link"
                    href="https://www.akamai.com/legal/compliance">Compliance</a
                  >
                </h2>
              </div>
            </div>
          </div>
          <div class="cmp-footer__list6">
            <div class="list cmp-list__gray-link">
              <ul class="cmp-list" id="list-55d2507c31">
                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/legal"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Legal </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/legal/compliance/privacy-trust-center"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Privacy Trust Center
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/legal/privacy-and-policies/manage-cookie-preferences"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        Cookie Settings
                      </span>
                    </a>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cmp-footer__section2">
      <div class="separator">
        <div id="separator-88d565469c" class="cmp-separator">
          <hr class="cmp-separator__horizontal-rule" />
        </div>
      </div>
    </div>
    <div class="cmp-footer__section3">
      <div class="horizontallist">
        <div class="cmp-horizontallist">
          <div class="cmp-horizontallist__text ">
            <div class="text text__color-gray">
              <div class="cmp-text">
                <p>©2022 Akamai Technologies</p>
              </div>
            </div>
          </div>

          <div class="cmp-horizontallist__list ">
            <div class="list cmp-list__gray-link">
              <ul class="cmp-list" id="list-6ffef3cb0f">
                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamai.com/legal/privacy-and-policies/emea-legal-notices"
                      target="_self"
                    >
                      <span class="cmp-list__item-title">
                        EMEA Legal Notices
                      </span>
                    </a>
                  </article>
                </li>

                <li class="cmp-list__item">
                  <article>
                    <a
                      class="cmp-list__item-link "
                      href="https://www.akamaistatus.com/"
                      target="_self"
                    >
                      <span class="cmp-list__item-title"> Service Status </span>
                    </a>
                  </article>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="css">
  
  @import "./stylesheets/akamai-components.css";
</style>
