<script>
  //Import assets
  import logo from "./images/akamai-logo.svg";
  import logo2 from "./images/akamai-logo2.svg";
  import phoneIcon from "./images/phone.svg";
  import infoGridImage from "./images/iStock-1326498296.png";
  import infoRowImage from "./images/iStock-1337631105.png";
  import foundationsIcon1 from "./images/icon-1.svg";
  import foundationsIcon2 from "./images/icon-2.svg";
  import foundationsIcon3 from "./images/icon-3.svg";

  //Components
  import Header from "./Header.svelte";
  import Hero from "./Hero.svelte";
  import InfoGrid from "./InfoGrid.svelte";
  import InfoRow from "./InfoRow.svelte";
  import Foundations from "./Foundations.svelte";
  import Form from "./Form.svelte";
  import Footer from "./Footer.svelte";

  export let title;

  let components = {
    header: {
      logo: logo,
      logo2: logo2,
      companyName: "Akamai",
    },
  };
</script>

{@html "<!--Header-->"}

<Header
  logo={components.header.logo}
  {phoneIcon}
  companyName={components.header.companyName}
/>

{@html "<!--Main-->"}

<main>
  <Hero />
  <InfoGrid {infoGridImage} />
  <InfoRow {infoRowImage}/>
  <Foundations
    icon1={foundationsIcon1}
    icon2={foundationsIcon2}
    icon3={foundationsIcon3}
  />
  <Form />
  <Footer logo={components.header.logo2} />
</main>

<!-- Global Styles-->
<style global lang="scss">
  /* General UI */

  $select-color: black;
  $select-bg: #3e8ed4;

  ::selection {
    color: black;
    background-color: #b3d4fc;
  }

  main {
    margin: 0 auto;
  }

  html {
    -webkit-font-smoothing: antialiased;
  }
  body {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  @media (min-width: 640px) {
    section {
      margin: 0 auto;
    }
    section .content {
      max-width: 1440px;
    }
  }

  /* Fonts */

  // Font family variables
  $RRegular: "Roboto Regular";
  $RMedium: "Roboto Medium";
  $RBold: "Roboto Bold";
  $RLight: "Roboto Light";

  // Font sizes
  .content h1 {
    --h1: 70px;
    --h1__mobile: 40px;

    font-family: $RBold;
    font-size: var(--h1);
    @media (min-width: 1024px) and (max-width: 1280px) {
      font-size: calc(var(--h1) * 0.9);
    }
    @media (min-width: 680px) and (max-width: 1024px) {
      font-size: calc(var(--h1) * 0.8);
    }
    @media (min-width: 370px) and (max-width: 679px) {
      font-size: var(--h1__mobile);
    }
    @media (min-width: 0px) and (max-width: 369px) {
      font-size: calc(var(--h1__mobile) * 0.9);
    }
  }

  .content h2 {
    --h2: 44px;
    --h2__mobile: 30px;
    font-family: $RLight;
    font-size: var(--h2);
    line-height: 1.227em;
    @media (min-width: 1024px) and (max-width: 1280px) {
      font-size: calc(var(--h2) * 0.9);
    }
    @media (min-width: 680px) and (max-width: 1024px) {
      font-size: calc(var(--h2) * 0.8);
    }
    @media (min-width: 0px) and (max-width: 679px) {
      font-size: var(--h2__mobile);
    }
  }
  .content button {
    font-family: $RBold !important;
    font-size: 15px;
    @media(max-width:544px) {
      font-size: 13px !important;
    }
  }
  .content label {
    font-family: $RMedium;
  }
  #info-row button {
    font-family: $RBold !important;
    font-size: 15px;
    width: 80%;
    @media (max-width: 370px) {
      font-size: 12px;
    }
  }
  #form button,
  #hero button, #foundations button {
    font-size: 15px;
    @media (max-width: 370px) {
      font-size: 12px !important;
    }
  }
  #hero .content button, #form .content button {
    font-family: $RBold !important;
  }

  #form .checkbox__text {
    font-size: 12px;
    font-family: $RRegular;
  }

  .content p {
    font-family: $RRegular;
    font-size: 18px;
    line-height: 1.444em;
    @media (min-width: 0px) and (max-width: 1024px) {
      font-size: 16px;
    }
  }

  .content h3 {
    font-family: $RRegular;
  }
  select, option {
    font-family: $RBold;
  }

  #foundations .content h3 {
    font-size: 18px;
  }

  #foundations .content h4 {
    font-family: $RRegular;
  }

  .content h4 {
    font-family: $RMedium;
  }
  

  @font-face {
    font-family: "Roboto Bold";
    src: url("../fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: "Roboto Light";
    src: url("../fonts/Roboto-Light.ttf") format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: "Roboto Medium";
    src: url("../fonts/Roboto-Medium.ttf") format("truetype");
    font-style: normal;
  }
  @font-face {
    font-family: "Roboto Regular";
    src: url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-style: normal;
  }
</style>
