<script>
  export let icon1;
  export let icon2;
  export let icon3;
</script>

{@html "<!--Foundations-->"}

<section id="foundations">
  <div class="content text-center flex flex-col">
    <div class="header__container">
      <h2 class="text-center">
        Data Science is the Foundation for Today’s Threat Intelligence
      </h2>
      <p class="foundations-item-trigger">
        This session starts with the latest findings on the internet threat
        landscape. <br/>The discussion shifts to what’s required to develop threat
        intelligence that meets seemingly conflicting objectives:
      </p>
    </div>
    <div
      class="flex-row__container flex-col m-auto md:flex-row flex justify-between w-auto md:w-full"
    >
      <div
        class="flex-col pb-14 md:pb-0 h-full flex justify-center items-center icon1 foundations__item"
      >
      <svg class="foundations-icon" overflow="visible" id="icon_1" data-name="icon 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="92.018" height="98.438" viewBox="0 0 92.018 98.438">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_17" data-name="Rectangle 17" width="92.018" height="98.076" fill="rgba(0,0,0,0)" stroke="#f93" stroke-width="2"/>
          </clipPath>
        </defs>
        <line id="Line_4" data-name="Line 4" x1="30.504" y1="78.226" transform="translate(39.155 19.85)" fill="none" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
        <g id="Group_14" data-name="Group 14" transform="translate(0 0)">
          <g id="Group_13" data-name="Group 13" clip-path="url(#clip-path)">
            <line id="Line_5" data-name="Line 5" x1="30.504" y2="78.226" transform="translate(4.798 19.85)" fill="none" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_9" data-name="Path 9" d="M22.924,9.01a5.3,5.3,0,0,1-3.372,4.937,5.33,5.33,0,0,1-3.852,0A5.3,5.3,0,1,1,22.924,9.01Z" transform="translate(19.604 5.904)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_10" data-name="Path 10" d="M4.946,48.874l7-17.937H46.782l7,17.937" transform="translate(7.865 49.195)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_11" data-name="Path 11" d="M31.279,35.26l5.377,13.847H9.042l5.38-13.847L22.85,13.707Z" transform="translate(14.379 21.796)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <line id="Line_6" data-name="Line 6" x1="16.857" transform="translate(28.801 58.118)" fill="none" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_12" data-name="Path 12" d="M6.726,48.7,25.994,31.254a.805.805,0,0,1,1.08,0L46.55,48.7" transform="translate(10.695 49.367)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_13" data-name="Path 13" d="M17.822,2.31a12.628,12.628,0,0,1,0,17.859" transform="translate(28.34 3.673)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_14" data-name="Path 14" d="M13.2,20.169a12.628,12.628,0,0,1,0-17.859" transform="translate(15.105 3.673)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_15" data-name="Path 15" d="M19.779.354a19.8,19.8,0,0,1,0,27.994" transform="translate(31.452 0.563)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_16" data-name="Path 16" d="M12.527,28.348a19.8,19.8,0,0,1,0-27.994" transform="translate(10.707 0.563)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <path id="Path_17" data-name="Path 17" d="M43.263,41.566V36.108a7.623,7.623,0,1,0-15.246,0v5.457" transform="translate(44.552 38.292)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <rect id="Rectangle_16" data-name="Rectangle 16" width="21.063" height="17.846" rx="0.331" transform="translate(69.66 79.859)" fill="rgba(0,0,0,0)" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
            <line id="Line_7" data-name="Line 7" y1="2.86" transform="translate(80.191 87.351)" fill="none" stroke="#f93" stroke-miterlimit="10" stroke-width="2"/>
          </g>
        </g>
      </svg>        <h3 class="foundations__h3 invisible">How coverage can be increased to block today’s diverse threats</h3>
      </div>
      <div
        class="flex-col pb-14 md:pb-0 h-full flex justify-center items-center icon2 foundations__item"
      >
      <svg class="foundations-icon" overflow="visible" id="icon_2" data-name="icon 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="81.301" height="95.009" viewBox="0 0 81.301 95.009">
        <defs>
          <clipPath id="clip-path2">
            <rect id="Rectangle_19" data-name="Rectangle 19" width="81.301" height="95.008" fill="none" stroke="#f93" stroke-width="2"/>
          </clipPath>
        </defs>
        <g id="Group_18" data-name="Group 18" transform="translate(0 0)" clip-path="url(#clip-path2)">
          <circle id="Ellipse_8" data-name="Ellipse 8" cx="20.568" cy="20.568" r="20.568" transform="translate(20.083 26.937)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <path id="Path_25" data-name="Path 25" d="M11.054,32.778A12.13,12.13,0,0,1,17.371,22a14.883,14.883,0,0,1,6.91-1.639A14.912,14.912,0,0,1,31.215,22a12.152,12.152,0,0,1,6.317,10.779" transform="translate(16.361 30.135)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <path id="Path_26" data-name="Path 26" d="M19.937,29.029h0a5.952,5.952,0,0,1-5.952-5.952V20.45a5.952,5.952,0,1,1,11.9,0v2.626A5.952,5.952,0,0,1,19.937,29.029Z" transform="translate(20.699 21.459)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <line id="Line_8" data-name="Line 8" y2="5.04" transform="translate(46.949 61.857)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <line id="Line_9" data-name="Line 9" y1="5.025" x2="0.002" transform="translate(34.351 61.871)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          <path id="Path_27" data-name="Path 27" d="M39.909,93.028S79.318,78.579,79.318,50.192v-37.7S61.68.5,39.909.5.5,12.494.5,12.494v37.7C.5,78.579,39.909,93.028,39.909,93.028Z" transform="translate(0.74 0.74)" fill="none" stroke="#f93" stroke-linejoin="round" stroke-width="2"/>
        </g>
      </svg>
              <h3 class="foundations__h3 invisible">
                Ensuring precision to reduce false positives that compromise customer experience
        </h3>
      </div>
      <div class="flex-col h-full flex justify-center items-center icon3 foundations__item">
        <svg overflow="visible" class="foundations-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="95" height="95" viewBox="0 10 95 95">
          <defs>
            <clipPath id="clip-path3">
              <rect id="Rectangle_18" data-name="Rectangle 18" width="95" height="95" transform="translate(0 0.008)" fill="none" stroke="#f93" stroke-width="2"/>
            </clipPath>
          </defs>
          <g id="icon_3" data-name="icon 3" transform="translate(0 -0.008)">
            <g id="Group_16" data-name="Group 16" clip-path="url(#clip-path3)">
              <path id="Path_19" data-name="Path 19" d="M42,37V51.617H56.617" transform="translate(34.737 30.602)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path id="Path_20" data-name="Path 20" d="M2,7H16.617V21.617" transform="translate(1.654 5.79)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path id="Path_21" data-name="Path 21" d="M63.782,81.392A45.678,45.678,0,0,0,16.19,4.654" transform="translate(13.39 0.827)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path id="Path_22" overflow="visilbe" data-name="Path 22" d="M17.009,7A45.678,45.678,0,0,0,64.6,83.737" transform="translate(0.827 5.79)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path id="Path_23" data-name="Path 23" d="M35.1,64.158s20.1-8.5,20.1-25.579v-20.1A42.887,42.887,0,0,0,35.1,13,42.887,42.887,0,0,0,15,18.481v20.1C15,55.655,35.1,64.158,35.1,64.158Z" transform="translate(12.406 10.752)" fill="none" stroke="#f93" stroke-linejoin="round" stroke-width="2"/>
              <path id="Path_24" data-name="Path 24" d="M41.925,23l-7.307,7.308-7.312,7.308L20,30.308" transform="translate(16.542 19.023)" fill="none" stroke="#f93" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
          </g>
        </svg>        <h3 class="foundations__h3 invisible">Improving agility to deter fast-changing attacks</h3>
      </div>
    </div>
    <footer>
      <h4>
        The session finishes with insights about how to measure overall security
        effectiveness.
      </h4>
      <button tabindex="-1"
        ><a href="./assets/Data Science is the Foundation for Contemporary Threat Intelligence White Paper.pdf" target="_blank">Download the Data Science White Paper</a
        ></button
      >
    </footer>
  </div>
</section>

<style lang="scss">
  $button-color: #017ac6;
  $border-color: #3e8ed4;
  $button-hover-color: #ff9933;
  
  section {
    background-color: white;
    .content {
      --content__padding--sides: 238px;
      --content__padding--sides-mobile: 40px;

      max-width: 1440px;
      padding: 72px var(--content__padding--sides) 69px
        var(--content__padding--sides);
      display: flex;
      margin: 0 auto;
      @media (min-width: 1024px) and (max-width: 1280px) {
        padding: 72px calc(var(--content__padding--sides) * 0.7) 69px
          calc(var(--content__padding--sides) * 0.7);
      }
      @media (min-width: 680px) and (max-width: 1023px) {
        padding: 72px calc(var(--content__padding--sides) * 0.4) 69px
          calc(var(--content__padding--sides) * 0.3);
      }
      @media (min-width: 0px) and (max-width: 679px) {
        padding: 30px var(--content__padding--sides-mobile) 30px
          var(--content__padding--sides-mobile);
      }
    }
    .flex-row__container {
      padding-bottom: 76px;
      margin-bottom: 24px;
      border-bottom: 1px solid $border-color;
      .flex-col {
        &.foundations__item {
          max-width: 278px;
          @media (min-width: 1024px) and (max-width: 1280px) {
            max-width: 238px;
      }
      @media (min-width: 680px) and (max-width: 1023px) {
        max-width: 208px;
      }

        }
        width: 100%;
        max-width: 238px;
        img {
          margin-bottom: 30px;
        }
        &.icon1 img {
          width: 92.02px;
          height: 98.08px;
        }
        &.icon2 img {
          width: 81.3px;
          height: 95.01px;
        }
        &.icon3 img {
          width: 95px;
          height: 95px;
        }
      }
    }
    button {
    background-color: #017ac6;
    color: #fff;
    margin-top: 24px;
    padding: 15px 24px;
    text-align: center;
    width: 100%;
    max-width: 340px;
    &:hover {
        transition: background-color 0.3s ease-in-out;
        background-color: #009cde;
      }
    }
    .header__container {
      --header__container-padding--sides: 98px;
      --header__container-padding--sides-mobile: 0px;

      padding: 0 var(--header__container-padding--sides);
      @media (max-width: 1439px) {
        br {
          display: none;
        }
      }
      @media (min-width: 1024px) and (max-width: 1280px) {
        padding: 0px calc(var(--header__container-padding--sides) * 0.7);
      }
      @media (min-width: 680px) and (max-width: 1023px) {
        padding: 0px calc(var(--header__container-padding--sides) * 0.4);
      }
      @media (min-width: 0px) and (max-width: 679px) {
        padding: 0 var(--header__container-padding--sides-mobile);
      }

      h2 {
        margin-bottom: 24px;
      }
      p {
        padding: 0 50px;
        margin-bottom: 67px;
        @media (min-width: 0px) and (max-width: 679px) {
          padding: 0px;
        }
      }
    }
  }
</style>
