<script>
  export let infoRowImage;
</script>

{@html "<!--Info Row-->"}

<section id="info-row" class="relative">
  <div class="panel__bg--left absolute" />
  <div
    class="panel__bg--right absolute w-full bg-cover h-full bg-image bg-cover bg-bottom h-full w-full bg-no-repeat"
    style="background-image: url({infoRowImage})"
  >
    <div class="overlay absolute z-0 text-left" />
  </div>

  <div class="content w-full">
    <div class="relative">
      <div class="flex-col md:flex-row flex overflow-hidden">
        <div
          class="content__block relative left z-50 text-center sm:text-left 2xl:flex 2xl:justify-end"
        >
          <h2>Secure Office <br /> Anywhere <br /> Spells Opportunity</h2>
        </div>
        <div
          class="content__block max-w-full right relative flex flex-col overflow-hidden"
        >
          <p class="text-white z-10 relative">
            Service providers are well-positioned to address new opportunities
            as more work moves outside of protected perimeters. Businesses will
            prioritize secure office anywhere solutions and providers can create
            new services that offer secure, frictionless access to the internet,
            and private data and business resources organizations host or manage
            themselves. With the right solutions providers can deliver IT teams
            the security and agility they need, and a great user experience with
            minimal overhead.
          </p>
          <a href="./assets/AI-SPS-Asavie-One-Pager-r3.pdf" target="_blank">
            <button tabindex="-1" class="z-10 relative text-center sm:text-left"
              >Get the SPS Asavie One-pager
            </button></a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  $panel-left: #ff9933;
  $panel-left-mobile: #000;
  $button-color: #017ac6;
  @media (max-width: 768px) {
    h2 {
      text-align: center;
    }
  }

  section {
    .panel__bg--left {
      @media (min-width: 1440px) {
        background-color: $panel-left;
        max-width: 44%;
        width: 100%;
        height: 100%;
      }
    }
    .panel__bg--right {
      @media (min-width: 1440px) {
        background-color: #000;
        max-width: 56%;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    max-width: 100%;
    background-color: white;
    .content {
      max-width: 1440px;
      margin: 0 auto;
    }
  }
  .content__block.left {
    --content__padding--left: 238px;
    --content__padding--right: 38px;
    --content__padding--mobile: 40px;

    padding: 90px var(--content__padding--right) 146px
      var(--content__padding--left);
    background-color: $panel-left;
    @media (min-width: 768px) {
      max-width: 44%;
      width: 100%;

      h2 {
        max-width: 376px;
      }
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
      padding: 90px calc(var(--content__padding--right) * 0.6) 146px
        calc(var(--content__padding--left) * 0.6);
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      padding: 90px calc(var(--content__padding--right) * 0.5) 146px
        calc(var(--content__padding--left) * 0.5);
    }
    @media (min-width: 545px) and (max-width: 768px) {
      max-width: 100%;
      background-color: $panel-left-mobile;
      color: white;
      padding: 60px calc(var(--content__padding--mobile) * 1.5) 10px
        calc(var(--content__padding--mobile) * 1.5);
    }
    @media (min-width: 0px) and (max-width: 544px) {
      background-color: $panel-left-mobile;
      color: white;
      max-width: 100%;
      padding: 30px var(--content__padding--mobile) 0px
        var(--content__padding--mobile);
    }
  }
  .content__block.right {
    --content__padding--left: 98px;
    --content__padding--right: 238px;
    --content__padding--mobile: 40px;
    background-size: cover;
    p {
      @media (min-width: 768px) {
        max-width: 474px;
      }
    }
    @media (min-width: 768px) {
      max-width: 56%;
      width: 100%;

      p {
        @media (min-width: 768px) {
          max-width: 474px;
        }
      }
    }

    @media (min-width: 1280px) {
      padding: 57px var(--content__padding--right) 57px
        var(--content__padding--left);
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
      padding: 90px calc(var(--content__padding--right) * 0.7) 57px
        calc(var(--content__padding--left) * 0.7);
    }
    @media (min-width: 678px) and (max-width: 767px) {
      max-width: 100%;
    }
    @media (min-width: 544px) and (max-width: 1024px) {
      padding: 70px calc(var(--content__padding--mobile) * 2) 100px
        calc(var(--content__padding--mobile) * 2);
    }
    @media (min-width: 0px) and (max-width: 543px) {
      max-width: 100%;
      padding: 40px var(--content__padding--mobile) 50px
        var(--content__padding--mobile);
    }
  }

  .overlay {
    background: linear-gradient(#000 0%, #000 40.39%, rgba(0, 0, 0, 0.65) 65%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  p {
    margin-bottom: 32px;
  }
  button {
    background-color: #017ac6;
    color: #fff;
    padding: 15px 24px;
    text-align: center;
    width: 80%;
    max-width: 280px;
    &:hover {
      transition: background-color 0.3s ease-in-out;
      background-color: #009cde;
    }
    // a:after {
    //   background: none repeat scroll 0 0 transparent;
    //   content: "";
    //   display: block;
    //   height: 2px;
    //   bottom: 0;
    //   top: 30px;
    //   left: 50%;
    //   position: absolute;
    //   background: $button-color;
    //   transition: width 0.2s ease 0s, left 0.2s ease 0s;
    //   width: 0;
    // }
    // a:hover:after {
    //   width: 100%;
    //   left: 0;
    // }
  }
</style>
